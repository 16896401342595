export function getToken() {
    const token = window.localStorage.getItem('token');
    return token ? JSON.parse(token) : null;
}
  
export function checkIfTokenExist(router) {
    if (!window.localStorage.getItem('token')) {
        router.push({ path: '/login' });
        return false;
    }
    return true;
}
  
export function checkIfSessionExpired(router) {
    const token = getToken();
    if (token) {
      const dateNow = new Date();
      const expirationDate = new Date(token.expiration);
      if (dateNow > expirationDate) {
        window.localStorage.removeItem('token');
        router.push({ path: '/login' });
      }
    }
}