import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { checkIfTokenExist, checkIfSessionExpired } from "@/components/globalMethods.js";

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/login.vue'),
    meta: { hideMenu: true }
  },
  {
    path: '/logout',
    component: () => import('@/views/logout.vue'),
    meta: { hideMenu: true }
  },
  {
    path: '/register',
    component: () => import('@/views/register.vue'),
    meta: { hideMenu: true }
  },
  {
    path: '/confirmEmailAndPhone',
    component: () => import('@/views/confirmEmailAndPhone.vue'),
    meta: { hideMenu: true }
  },
  {
    path: '/sideMenuPages/home',
    component: () => import('../views/sideMenuPages/home.vue'),
  },
  {
    path: '/sideMenuPages/preRegisterCpf',
    component: () => import('../views/sideMenuPages/preRegisterCpf.vue'),
  },
  {
    path: '/sideMenuPages/registerUser',
    component: () => import('../views/sideMenuPages/registerUser.vue'),
  },
  {
    path: '/sideMenuPages/updateUser',
    component: () => import('../views/sideMenuPages/updateUser.vue'),
  },
  {
    path: '/sideMenuPages/privacyPolicy',
    component: () => import('../views/sideMenuPages/privacyPolicy.vue'),
  },
  {
    path: '/sideMenuPages/termsOfUse',
    component: () => import('../views/sideMenuPages/termsOfUse.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/sideMenuPages/')) {
    if (checkIfTokenExist(router)) {
      checkIfSessionExpired(router);
    }
  }
  next();
});


export default router
